import client, { tokenConfig } from './apiClient'
import { getLanguageOfBrowser } from '../../utils/misc'

export function fecthGetFinance (token, params) {
  return client().post(`/simulation`, params, tokenConfig(token))
}

export function fecthSendData (params) {
  return client().post(`/user/simulation-data`, { ...params }, {
    headers: {
      'Content-Language': getLanguageOfBrowser(),
    }
  })
}
