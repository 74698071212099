import { message } from 'antd'
import queryString from 'query-string'
import {
  CHANGE_LOAN_AMOUNT,
  CHANGE_MONTHLY_PAYMENT,
  CHANGE_PROTECTION_INSURANCE,
  FECTH_REQUEST_GET_FINANCE,
  FECTH_REQUEST_GET_FINANCE_SUCCESS,
  FECTH_REQUEST_GET_FINANCE_ERROR,
  FECTH_REQUEST_SEND_DATA,
  FECTH_REQUEST_SEND_DATA_SUCCESS,
  FECTH_REQUEST_SEND_DATA_ERROR,
  NEXT_STEP,
  PREVIOUS_STEP,
  SET_STEP
} from '../constants/simulation'

import { fecthGetFinance, fecthSendData } from '../api/simulation'
import { simulationSteps } from '../../routes' // eslint-disable-line
import {
  parseJSON, pushToUrl, errorToMessage
} from '../../utils/misc'

import history from '../../utils/history'

message.config({
  top: 50,
  duration: 3,
  maxCount: 4
})

export const changeLoanAmount = loanAmount => (dispatch) => {
  dispatch({ type: CHANGE_LOAN_AMOUNT, payload: loanAmount })
}

export const changeMonthyPaymet = monthlyPayment => (dispatch) => {
  dispatch({ type: CHANGE_MONTHLY_PAYMENT, payload: monthlyPayment })
}

export const changeProtectionInsurance = protectionInsurance => (dispatch) => {
  dispatch({ type: CHANGE_PROTECTION_INSURANCE, payload: protectionInsurance })
  pushToUrl({ params: { protectionInsurance } })
}

export const getFinance = recaptchaToken => (dispatch, getState) => {
  const { token } = getState().auth
  const { loanAmount, monthlyPayment, protectionInsurance = '' } = queryString.parse(history.location.search)

  const params = {
    loanAmount: parseInt(loanAmount, 0),
    monthlyPayment: parseInt(monthlyPayment, 0),
    recaptchaToken
  }

  dispatch({ type: CHANGE_PROTECTION_INSURANCE, payload: protectionInsurance.toLowerCase() === 'true' })
  dispatch({ type: FECTH_REQUEST_GET_FINANCE })

  return fecthGetFinance(token, params)
    .then(parseJSON)
    .then((data) => {
      dispatch({
        type: FECTH_REQUEST_GET_FINANCE_SUCCESS,
        payload: data
      })
    })
    .catch((err) => {
      console.log(err)
      const { response } = err
      dispatch({
        type: FECTH_REQUEST_GET_FINANCE_ERROR,
        payload: errorToMessage(response ? response.data : {})
      })
    })
}

export const getFinanceWithParams = (recaptchaToken, payload) => (dispatch, getState) => {
  const { token } = getState().auth
  const { loanAmount, monthlyPayment, protectionInsurance = '' } = payload

  const params = {
    loanAmount: parseInt(loanAmount, 0),
    monthlyPayment: parseInt(monthlyPayment, 0),
    recaptchaToken
  }

  dispatch({ type: CHANGE_PROTECTION_INSURANCE, payload: protectionInsurance })
  dispatch({ type: FECTH_REQUEST_GET_FINANCE })

  return fecthGetFinance(token, params)
    .then(parseJSON)
    .then((data) => {
      dispatch({
        type: FECTH_REQUEST_GET_FINANCE_SUCCESS,
        payload: data
      })
    })
    .catch((err) => {
      const { response: { data } } = err
      dispatch({
        type: FECTH_REQUEST_GET_FINANCE_ERROR,
        payload: errorToMessage(data)
      })
    })
}

export const sendData = (values, recaptchaToken) => (dispatch, getState) => {
  const {
    simulation: {
      result: {
        REGULAR = {},
        PROTECTION_INSURANCE = {}
      },
      protectionInsurance
    }
  } = getState()
  const params = {
    ...values,
    loanData: {
      type: protectionInsurance ? 'PROTECTION_INSURANCE' : 'REGULAR',
      ...protectionInsurance ? PROTECTION_INSURANCE : REGULAR
    },
    recaptchaToken
  }
  dispatch({
    type: FECTH_REQUEST_SEND_DATA
  })

  return fecthSendData(params)
    .then(parseJSON)
    .then(() => {
      dispatch({
        type: FECTH_REQUEST_SEND_DATA_SUCCESS
      })
    })
    .catch((err) => {
      const { response: { data } } = err
      dispatch({
        type: FECTH_REQUEST_SEND_DATA_ERROR,
        payload: errorToMessage(data)
      })
    })
}

export const setStep = stepNumber => ({
  type: SET_STEP,
  payload: stepNumber
})

export const nextStep = params => (dispatch, getState) => {
  const {
    simulation: {
      currentStep
    }
  } = getState()
  const toNextStep = currentStep <= 4 ? currentStep + 1 : currentStep
  const nextStepUrl = simulationSteps[toNextStep].route

  pushToUrl({ path: nextStepUrl, params })
  dispatch({
    type: NEXT_STEP,
    payload: toNextStep
  })
}

export const previousStep = () => (dispatch, getState) => {
  const {
    simulation: {
      currentStep
    }
  } = getState()
  const toPreviousStep = currentStep > 0 ? currentStep - 1 : currentStep
  const previousStepUrl = simulationSteps[toPreviousStep].route
  pushToUrl({ path: previousStepUrl })
  dispatch({
    type: PREVIOUS_STEP,
    payload: toPreviousStep
  })
}
