import React from 'react'
import { withRouter } from 'react-router'
import {
  Redirect
} from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import history from './history'

import i18n from './i18n'

const toNumber = value => Number(value)
export const isNumber = value => !Number.isNaN(Number(value))
const isFloat = n => Number(n) === n && n % 1 !== 0

export function parseJSON (response) {
  return response.data
}

/**
 *
 * @param {String} value "100.000"
 */
export const formatterAmountToNumber = (value) => {
  try {
    return Number(value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ``))
  } catch (err) {
    return 0
  }
}

export function formatterAmountFix (v) {
  const value = toNumber(v)
  if (!isNumber(value)) return '0'
  const fixed = isFloat(value) ? 2 : 0
  let amount
  try {
    amount = value.toFixed(fixed).split('.')
  } catch (error) {
    console.log({ value }) // eslint-disable-line
    console.log({ error }) // eslint-disable-line
  }
  amount[0] = amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  return amount.join(',')
}

export function createReducer (initialState, reducerMap) {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type]

    return reducer ? reducer(state, action.payload) : state
  }
}

/**
 * get Porcentage
 *
 * @param {number} [total=0] total
 * @param {number} [portion=5] portion in porcentaje
 * @param {number} [fixed=0] decimals
 */
export const porcentaje = (total = 0, portion = 5, fixed = 0) => (
  ((portion * total) / 100).toFixed(fixed)
)

export const RedirectWithQuery = withRouter(props => (
  <Redirect {...props} to={props.to + props.location.search} />
))

/**
 *
 * @param {[object]} path /[path] in url, default: current
 * @param {[object]} query /? in url, if null \
 * the query reset to empty default: ?params1=value1,params2=value2
 */
export const pushToUrl = ({ path, params }) => {
  const { location: { pathname, search } } = history
  const newQuery = params !== null ? `?${queryString.stringify({ ...queryString.parse(search), ...params })}` : ''
  const newPathName = path || pathname
  history.push(`${newPathName}${newQuery}`, {
    path: `${newPathName}${newQuery}`
  })
}

export const getUrlParams = () => {
  const { location: { search } } = history
  return queryString.parse(search)
}

export const errorToMessage = (errors) => {
  const replace = (message, x) => _.trim(message.replace(new RegExp('"(.*?)"'), '').replace(x, ''))
  const errorNumber = (message, context) => {
    const { key, limit } = context
    const cleanMessage = replace(message, limit)
    return i18n.t(`error.${cleanMessage}`, { val1: i18n.t(`${key}`), val2: `$${formatterAmountFix(limit)}` })
  }
  const errorRequired = (message, context) => {
    const { key } = context
    const cleanMessage = replace(message, key)
    return i18n.t(`error.${cleanMessage}`, { val1: i18n.t(`${key}`) })
  }
  const errorMsg = message => i18n.t(`error.${message.trim()}`)

  const errorUnknown = (message, context) => i18n.t('error.internal')

  const match = {
    'number.min': errorNumber,
    'number.max': errorNumber,
    'any.required': errorRequired,
    'object.unknown': errorUnknown
  }

  const { details, message: regularMessage } = errors

  if (_.isArray(details)) {
    return details.map(({
      type, message, context, ...val
    }) => (_.hasIn(match, type) ? match[type](message, context) : console.error(errors))) // eslint-disable-line
  }
  if (regularMessage) {
    return [errorMsg(regularMessage)]
  }
  return []
}

export function getByIdMap (list, idField = '_id') {
  const result = {}
  list.forEach((item) => {
    result[item[idField]] = item
  })
  return result
}

export function getDocType (mimeType) {
  if (mimeType.includes('image')) return 'img'
  if (mimeType.includes('pdf')) return 'pdf'
  return undefined
}

export function getLanguageOfBrowser () {
  const language = (navigator.languages && navigator.languages[0])
    || navigator.language
    || navigator.userLanguage
  return language
}
